/* 
.inputfield.border-bottom-only {
    border: none;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    outline: none;
    padding: 5px 10px;
    box-shadow: none;
    
  }
  
  .inputfield.border-bottom-only:focus {
    border-bottom: 2px solid #EF4C0B; 
    outline: none;
    box-shadow: none;
  }
  
  .custom-checkbox .form-check-input {
    border: 1px solid black;  
  }

  
   */

   /* General Styles */
.inputfield.border-bottom-only {
    border: none;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    outline: none;
    padding: 5px 10px;
    box-shadow: none;
}

.inputfield.border-bottom-only:focus {
    border-bottom: 2px solid #EF4C0B; /* Highlight color on focus */
    outline: none;
    box-shadow: none;
}

.custom-checkbox .form-check-input {
    border: 1px solid black; /* Add black border to the checkbox */
}

/* Media Queries for Mobile Screens */

/* 1. For small screens (up to 576px) */
@media only screen and (max-width: 576px) {
    .signinfrm .form-container {
        padding: 0 5%; /* Reduce padding for smaller screens */
    }

    .signinfrm h2 {
        font-size: 24px; /* Adjust header font size for mobile */
    }

    .signinfrm h2,
    .signinfrm  p {
      text-align: center;
      margin: 0 auto; /* Center horizontally */
    }
    .signinfrm p {
        font-size: 14px; /* Adjust text size for mobile */
    }

    .inputfield.border-bottom-only {
        font-size: 14px; /* Smaller input text for mobile screens */
        padding: 8px 10px; /* Increased padding for better accessibility */
    }

    .form-check-input {
        width: 20px; /* Smaller checkbox for mobile */
        height: 20px; /* Smaller checkbox for mobile */
    }

    .form-group {
        margin-bottom: 15px; /* Reduced margin for smaller screens */
    }

    /* Adjust layout for phone number and OTP input */
    .signinfrm .form-container .row {
        display: block;
    }

    .signinfrm .form-container .col-6 {
        width: 100%; /* Make inputs full width for smaller screens */
        margin-bottom: 15px;
    }

    .btn {
        font-size: 16px; /* Adjust button text size */
        padding: 10px; /* Adjust button padding */
    }

    /* For the "How It Works" section */
    .signinfrm ol {
        font-size: 14px; /* Adjust font size of the list */
        padding-left: 20px; /* Ensure proper indentation */
    }

    .signinfrm ol li {
        margin-bottom: 15px;
    }

    /* Adjust form layout for mobile */
    .signinfrm .col-lg-6,
    .signinfrm .col-md-8,
    .signinfrm .col-sm-8 {
        width: 100%; /* Ensure these columns take full width */
    }

    /* Hide the image for mobile view */
    .signinfrm img {
        display: none; /* Optionally hide image on smaller screens */
    }
}

/* 2. For larger screens, we maintain your default layout */
@media only screen and (min-width: 577px) {
    .signinfrm .form-container {
        padding: 0 10%; /* Maintain original padding for larger screens */
    }

    .signinfrm h2 {
        font-size: 30px; /* Larger header font size for bigger screens */
    }

    

    .signinfrm p {
        font-size: 16px; /* Larger text size */
    }

    .signinfrm .form-group {
        margin-bottom: 20px; /* Space between form groups */
    }

    .signinfrm ol {
        font-size: 16px; /* Larger text for the "How It Works" section */
    }

    .signinfrm .col-lg-6,
    .signinfrm .col-md-8,
    .signinfrm .col-sm-8 {
        width: 50%; /* Two-column layout for larger screens */
    }

    .signinfrm .col-lg-6 {
        margin-bottom: 20px;
    }
}

/* 3. For tablets (up to 768px) */
@media only screen and (max-width: 768px) {
    .signinfrm .form-container {
        padding: 0 8%; /* Adjust padding for tablets */
        

    }

    .signinfrm h2 {
        font-size: 26px; /* Adjust header font size */
    }

    .signinfrm .col-md-6 {
        width: 100%; /* Make inputs full width for tablets */
    }
    /* .signinfrm .col-md-10 {
        width: 70%; 
       
    } */
    

    .signinfrm ol {
        font-size: 15px; /* Adjust font size */
    }

    .signinfrm .btn {
        font-size: 18px; /* Slightly bigger button on tablets */
        padding: 12px; /* Bigger button padding */
    }
}

.custom-list {
    list-style: decimal;
  }
  .custom-list li::marker {
    font-weight: bold;
  }