/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h2.signuph2 {
  font-size: 24px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-sm {
  font-size: 14px;
  min-width: 800px;
}

/* Mobile View Adjustments */
@media only screen and (max-width: 767px) {
  /* Full screen background image */
  .background-image {
    background-size: contain; /* To prevent it from stretching */
    background-position: center center;
    background-repeat: no-repeat;
    height: 300px; /* Adjust height for mobile view */
  }

  .signuph2 {
    font-size: 20px;
    padding-top: 10px;
  }

  /* Sidebar for mobile view */
  .d-md-block {
    display: none !important;
  }
  .d-none {
    display: block !important;
  }

  .d-block.d-md-none {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .sidebar {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 250px;
    height: 100%;
    background-color: #f8f9fa;
    padding: 10px;
  }

  .table-responsive {
    margin-bottom: 20px;
  }

  /* Coupon Table Styling */
  .table-sm th, .table-sm td {
    font-size: 12px;
    padding: 8px;
  }

  /* Coupon View Button */
  .table-sm button {
    font-size: 12px;
    padding: 5px 10px;
  }

  /* Pagination Styling */
  .d-flex.justify-content-center button {
    font-size: 10px;
    padding: 5px 10px;
  }

  .table-sm th {
    font-size: 12px;
  }

  /* Adjust Modal */
  .modal-dialog {
    max-width: 90%;
  }

  .modal-header {
    padding: 10px;
  }

  .modal-body {
    font-size: 12px;
  }

  .modal-title {
    font-size: 16px;
    text-align: center;
  }
}

/* Tablet View Adjustments */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .background-image {
    height: 400px;
  }

  .signuph2 {
    font-size: 22px;
  }

  /* Sidebar for tablet view */
  .d-md-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  .table-responsive {
    margin-bottom: 20px;
  }

  .table-sm th, .table-sm td {
    font-size: 14px;
    padding: 8px;
  }

  .d-flex.justify-content-center button {
    font-size: 12px;
    padding: 5px 10px;
  }

  .modal-body {
    font-size: 14px;
  }

  .modal-title {
    font-size: 18px;
  }
}

/* Add this to your external CSS */
.custom-container {
  background-color: white;
  border-radius: 8px;  /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
  padding: 20px;  /* Padding around the content */
  margin-bottom: 20px;  /* Space at the bottom */
}

/* Mobile and tablet adjustments */
@media only screen and (max-width: 767px) {
  .custom-container {
    padding: 15px;  /* Less padding on mobile */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container {
    padding: 18px;  /* Slightly more padding for tablets */
  }
}




/* 
@media screen and (max-width: 430px) {
  .maincardd {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin: 10px;
  }

  .maincardd img {
    width: 100%;
    height: auto; 
    object-fit: cover;
  }

  .maincardd h5 {
    font-size: 16px; 
  }

  .maincardd p {
    font-size: 14px; 
  }

  .maincardd button {
    width: 100%;
    padding: 10px;
    font-size: 14px; 
  }
}


  
  .custom-coupon-image {
   
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .custom-coupon-details {
    padding: 10px;
  }
  
  
  .custom-hotel-name {
    font-weight: bold;
    margin: 0;
  }
  
  .custom-location-city {
    color: #777;
  }
  
  .custom-coupon-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .custom-coupon-actions button {
    margin: 0;
  }
  
  
  .cardpop{
    border:2px #6C7594 dashed;
   padding-bottom: 10px;

   
   
  }
  
  
  
img.img-fluid {
  max-width: 100%;
  height: auto;
}

.custom-coupons-container {
  display: flex;
  flex-wrap: wrap;
 
}


.custom-hotel-name {
  font-size: 1.2rem;
  font-weight: bold;
}


@media (max-width: 767px) {
  .navbar-collapse {
    margin-top: 20px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    font-size: 1.5rem;
  }

  img.img-fluid {
    max-width: 100%;
    height: auto;
  }

  .maincardd .custom-coupon {
    flex-direction: column;
    text-align: center;
   
    width: 100%;
  }

  .custom-hotel-name {
    font-size: 1.1rem;
  }

  

  .cardpop {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .cardpop img {
    width: 80px;
    height: 80px;
  }

  .qr-code-img {
    width: 100px;
    height: auto;
  }

  
  .maincardd .btn {
    width: 100%;
    padding: 10px 0;
  }

  

  .pl-5 {
    padding-left: 10px !important;
  }

  .qr-code-img {
    width: 80px;
    height: auto;
  }

  
  .modal-content {
    padding: 10px;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .custom-hotel-name {
    font-size: 1.4rem;
  }

  .maincardd .custom-coupon {
    width: 48%;
    margin: 10px 1%;
  }

  .cardpop {
    padding: 15px;
  }

  .cardpop img {
    width: 100px;
    height: 100px;
  }

  .qr-code-img {
    width: 120px;
    height: auto;
  }

  .btn {
    padding: 10px;
  }
}


@media (min-width: 1024px) {
  .navbar-nav {
    display: flex;
    justify-content: flex-end;
  }

  

  .custom-hotel-name {
    font-size: 1.6rem;
  }

  .cardpop {
    flex-direction: row;
    justify-content: flex-start;
  }

  .btn {
    padding: 12px;
  }

  

  .pl-5 {
    padding-left: 40px !important;
  }
}

.cardpop {
  display: flex;
  justify-content: center;
  
  flex-direction: column;
  
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}




.slider-img {
  width: 100%;          
  height: 55vh;          
}


@media (max-width: 768px) {
  .slider-img {
    width: 100%;  
    height: 40vh;        
    object-fit: cover;  
   
  }
}

@media (max-width: 576px) {
  .slider-img {
    height: 30vh;        
  }
}

.dropdown-menu {
  border-radius: 0 !important;
}


.dropdown1{
  background-color: black;
  color: white;
}


.dropdown1:hover {
  background-color: #EF4C0B;
  color: white;
}


.dropdown1:active {
  background-color: #EF4C0B;
  color: white;
} */
