/* src/components/TermsAndConditions.css */
.terms-container {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 10px; */
    font-family: Arial, sans-serif;
    line-height: 1.3;
    text-align: justify;
   
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
}

