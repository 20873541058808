
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.dineinimage {
  background-image: url('../assets/Illustration.png');
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.bgimage {
  background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../assets/bglogin1.png');
  background-repeat: repeat;
  height: 100vh;
}

.signinh3 {
  color: #0D0C22;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
}

.forminput {
  color: #0D0C22;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

 /* General Styles */
 .inputfield.border-bottom-only {
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  outline: none;
  padding: 5px 10px;
  box-shadow: none;
}

.inputfield.border-bottom-only:focus {
  border-bottom: 2px solid #EF4C0B; /* Highlight color on focus */
  outline: none;
  box-shadow: none;
}

.custom-checkbox .form-check-input {
  border: 1px solid black; /* Add black border to the checkbox */
}

.custom-file-input{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  color: #777;
  cursor: pointer;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-list {
  list-style: decimal;
}
.custom-list li::marker {
  font-weight: bold;
}