/* Sidebar.css */

  .sb{
    margin-bottom: 10px ;
    padding: 10px;
    font-size: 16px;
    color: white;
    text-decoration: none;
    /* border-bottom: 1px solid white; */
  }
  
  .sb:hover {
    background-color: #495057;
    /* border-radius: 4px; */
  }
  /* .sb:active {
    background-color: #495057;
    border-bottom: 1px solid white;
  }
   */


   

  