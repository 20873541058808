
/* Media queries for responsiveness */

.slick-dots {
  display: flex !important; /* Ensure dots align horizontally */
  justify-content: center;  /* Center the dots */
  gap: 0;               /* Add spacing between dots */
  list-style: none;         /* Remove default list styling */
  padding: 0;
 
}



.slick-dots li button {
  width: 1px !important;       /* Diameter of the disc */
  height: 1px !important;      /* Diameter of the disc */
  border-radius: 50%; /* Make the button circular */
  background-color: gray;       /* Gray fill color for the dots */
  border: 1px solid black;      /* Black border around the dots */
  padding: 0;
  margin: 5px;                  /* Add space between dots */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-dots li.slick-active button {
  background-color: black; /* Color for active dot */
 
}


/* For screens smaller than 576px (Mobile) */
@media (max-width: 576px) {
  .maincardd {
    padding: 10px;  /* Reduce padding for mobile */
  }
  .maincardd > div {
    min-height: auto;  /* Remove fixed height for mobile */
    height: auto;      /* Allow height to adjust based on content */
  }
  .maincardd img {
    height: 120px; /* Smaller image height for mobile */
  }
  .maincardd .btn {
    font-size: 12px;  /* Smaller button text on mobile */
  }
}

/* For screens between 577px and 768px (Tablet) */
@media (max-width: 768px) {
  .maincardd > div {
    min-height: auto;  /* Remove fixed height for tablet */
    height: auto;
  }
  .maincardd img {
    height: 130px; /* Adjust image height for tablet */
  }
  .maincardd .btn {
    font-size: 13px;  /* Slightly smaller button text for tablet */
  }
}

/* For screens larger than 768px (Laptop/desktop) */
@media (min-width: 768px) {
  .maincardd > div {
    min-height: 350px;  /* Keep the height more consistent on larger screens */
    height: 400px;
  }
}

.responsive-slider-img {
  width: 100%;                /* Full width of the container */
  height: 60vh;               /* 60% of the viewport height */
  border-radius: 70px;        /* Reduced border radius for better mobile appearance */
  object-fit: fill;          /* Ensures images cover the container without distortion */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  



}

/* Responsive adjustments */
@media (max-width: 992px) {
  .responsive-slider-img {
    height: 50vh;             /* Slightly reduce height for tablets */
  }
}

@media (max-width: 768px) {
  .responsive-slider-img {
    height: 25vh; 
    border-radius:30px;            /* Further reduce height for smaller screens */
  }
}

@media (max-width: 576px) {
  .responsive-slider-img {
    height: 20vh;   
    border-radius:30px;          /* Adjust height even more for mobile devices */
  }
}




.slider-img {
  width: 100%;            
  height: 60vh;           
  border-radius: 70px;    
}

/* Main card container responsiveness */
.maincardd {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: 20px;
  padding: 15px;
}

/* Custom coupon card responsiveness */
.custom-coupon {
  width: 102%;
  /* max-width: 200px; */
  margin: 10px;
  flex: 1 1 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

@media (min-width: 576px) {
  .custom-coupon {
    flex: 1 1 48%;
  }
}
@media (max-width: 576px) {
  .maincardd > div {
    width: 95%; /* Make the card take almost full width on smaller screens */
  }
}

@media (min-width: 768px) {
  .custom-coupon {
    flex: 1 1 32%;
  }
}

@media (min-width: 992px) {
  .custom-coupon {
    flex: 1 1 24%;
  }
}

@media (min-width: 1200px) {
  .custom-coupon {
    flex: 1 1 20%;
  }
}

/* Coupon image responsiveness */
.custom-coupon-image {
  width: 100%;
  height: auto;
  max-height: 120px;
  object-fit: cover;
}

/* Button and text adjustments */
.custom-coupon-details {
  /* text-align: center; */
}

.custom-coupon-details h6 {
  font-size: 1rem;
}

.custom-coupon-details button {
  font-size: 14px;
  padding: 4px 8px;
  
}




.custom-coupons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}



.custom-coupon-image {
  
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.custom-coupon-details {
  padding: 10px;
}


.custom-hotel-name {
  font-weight: bold;
  margin: 0;
}

.custom-location-city {
  color: #777;
}

.custom-coupon-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.custom-coupon-actions button {
  margin: 0;
}


.cardpop{
  border:2px #6C7594 dashed;
 padding-bottom: 10px;

 
 
}

.cpcode{
text-align: center;
}  

img.img-fluid {
max-width: 100px;
height: 100px;
}

.custom-coupons-container {
display: flex;
flex-wrap: wrap;

}



.custom-hotel-name {
font-size: 1.2rem;
font-weight: bold;
}

.cardpop {
  
  justify-content: center;
  
  flex-direction: column;
  
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}





.pagination-controls .page-btn{
  padding: 4px 7px;
  
}


.pagination-controls .active{
  font-weight: bold;
}


.Toastify__toast-container {
 
  left: 40% !important; 
  transform: translate(-50%, -50%); 
}





 @media (min-width: 991px) and (max-width: 1200px) {
  .custom-coupon {
    flex: 1 1 30%; /* Adjust this percentage for optimal fit */
  }

  .custom-coupon-details h6 {
    font-size: 0.9rem; /* Slightly adjust font size if necessary */
  }

  .custom-coupon-image {
    height: 100px; /* Adjust height to fit better on smaller screens */
  }

  .custom-coupon button {
    font-size: 12px; /* Reduce button font size for better fit */
    padding: 4px 6px;
  }
}


/* Coupon card container */
.coupon-card {
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
  border-radius: 8px;
  border: none; /* Remove border from card to avoid overlapping */
  padding: 10px;
  overflow: hidden;

  /* Add border through box-shadow instead to avoid internal lines */
  box-shadow: inset 0 0 0 1px #0b0909;
}

/* Pseudo-elements to create the circular cutouts */
.coupon-card::before,
.coupon-card::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid #0b0909;
  border-radius: 50%;
  z-index: 1; /* Ensure the cutout circles are on top */
}

/* Left cutout */
.coupon-card::before {
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}

/* Right cutout */
.coupon-card::after {
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
}

/* Dashed line in the middle */
.dashed-line {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 150px; /* Adjust based on the logo width */
  width: 1px;
  border-left: 2px dashed #ccc;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 375px) { /* iPhone SE */
  .coupon-card {
    padding: 8px; /* Reduce padding */
  }

  .coupon-card::before,
  .coupon-card::after {
    width: 20px;
    height: 20px;
  }

  .dashed-line {
    left: 120px; /* Adjust for smaller width */
  }
}

@media (max-width: 430px) { /* iPhone 14 */
  .coupon-card {
    padding: 10px;
  }

  .dashed-line {
    left: 130px;
  }
}


/* Coupon card container */
.coupon-card2 {
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
  border-radius: 8px;
  border: none; /* Remove border from card to avoid overlapping */
  padding: 10px;
  overflow: hidden;

  /* Add border through box-shadow instead to avoid internal lines */
  box-shadow: inset 0 0 0 1px #0b0909;
}

/* Pseudo-elements to create the circular cutouts */
.coupon-card2::before,
.coupon-card2::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid #0b0909;
  border-radius: 50%;
  z-index: 1; /* Ensure the cutout circles are on top */
}

/* Left cutout */
.coupon-card2::before {
  top: 60%;
  left: -15px;
  transform: translateY(-50%);
}

/* Right cutout */
.coupon-card2::after {
  top: 60%;
  right: -15px;
  transform: translateY(-50%);
}


