/* Header.css */
.navbar-toggler {
  border: none !important; 
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="rgb(239, 76, 11)" stroke-width="2" d="M5 7h20M5 15h20M5 23h20" /%3E%3C/svg%3E')!important; 
  height: 50px !important;
  width: 50px !important;
  
  
}

.navbar-nav .nav-link {
  color: #FFFFF3 !important; /* Ensures text color of nav links */
}

/* button:hover {
  background-color: #e6a6a6; 
} */