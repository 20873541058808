.close-icon {
  font-size: 30px;            /* Adjust size of the cross */
  font-weight: bold;          /* Make it bold */
  color: black;            /* Set color of the cross */
  cursor: pointer;            /* Show pointer cursor on hover */
  background: none;           /* No background */
  border: none;               /* No border */
  outline: none;              /* No outline */
  /* position: absolute;         /*Position it absolutely in the container */
   /* top: 10px;                 Adjust as necessary */
  /* right: 15px;                Adjust as necessary */
}

.paybox {
  /* border: 1px; */
  /* border-radius: 20px; */
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../assets/bglogin1.png'); */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  /* padding: 20px; */
}
.regpay{
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../assets/bglogin1.png'); */
}
.paybutton {
  width: 200px;
  background-color: #9563FF;
}

.paycontent {
  /* padding: 50px; */
}

.payingbox {
  border-radius: 20px;
  padding: 40px;
  background: linear-gradient(to bottom, #F0F0F0, #9563FF, #F0F0F0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payingcontent {
  padding: 30px;
  padding: 0 -40px;
  margin: 0 50px;
}

.payline {
  border-bottom: 1px solid;
  color: #575757;
  margin-top: 200px;
}

/* Media Queries */

/* For tablets */
@media (max-width: 768px) {
  .paycontent, .payingcontent {
    padding: 20px;
  }

  .paybutton {
    width: 100%;
  }

  .payline {
    margin-top: 100px;
  }

  .payingbox {
    width: 100%;
    padding: 30px;
    margin-left: 0;
  }
}

/* For mobile screens */
@media (max-width: 576px) {
  .paybox {
    border-radius: 10px;
  }

  .paybutton {
    width: 100%;
    padding: 15px;
  }

  .payingbox {
    padding: 15px;
    width: 100%;
  }

  .payline {
    margin-top: 50px;
  }
}
.qr-code-container{
  display: flex;
}
/* Default behavior for larger screens */
.bank-details {
display: flex;
flex-wrap: wrap;
align-items: flex-start;
}

.qr-code {
order: 1; /* By default, QR code will be after the details */
display: flex;
justify-content: center;
align-items: center;
width: 100%; /* Takes up full width in its column */
}

/* For mobile view (max width 768px, can adjust based on your needs) */
@media (max-width: 768px) {
.bank-details {
  flex-direction: column;
}

.qr-code {
  order: 2; /* Move QR code to the bottom in mobile view */
  margin-top: 10px; /* Optional: Adds space between bank details and QR code */
}
}
