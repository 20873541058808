@media (max-width: 768px) {
    /* For tablets and small devices */
    h3 {
      font-size: 1.4rem;  /* Adjust title size for smaller screens */
    }
    h6 {
      font-size: 1rem;  /* Adjust subtitle size */
    }
    img {
      max-width: 100%;
    }
    .modal-body {
      padding: 10px;
    }
  }
  
  @media (max-width: 576px) {
    /* For mobile screens */
    h3 {
      font-size: 1.2rem;  /* Smaller font size */
    }
    h6 {
      font-size: 0.9rem;  /* Adjust subtitle */
    }
    .modal-body {
      padding: 5px;
    }
    .img {
      max-width: 80%;
    }
  }
  