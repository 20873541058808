.containerbg{
    background-color: white;
    border-radius: 10px;
    /* margin-left: 50px; */
    box-shadow: 0 4px 8px rgba(0.1, 0.1, 0.1, 0.2); 
    /* width: 200px; */
}

.formdecor{
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    line-height: 10px;
   
}
.lableclass{
    color: #EF4C0B;
    font-weight: bold;
    line-height: 20px;
}
.lableclasss{
    line-height: 15px;
}

.inputfield1{
    /* height: 50px; */
    border: 1px solid black;
    border-radius: 20px;
}

.boxcheck{
    border: 2px solid #e7e7e7;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* h1{
    font-family: Montserrat;
} */

/* Custom Dropdown Styling */
.custom-dropdown {
    border-color:  #333 !important; /* Dark border */
    border-radius: 5px; /* Rounded corners */
    /* height: 50px; Custom height */
    background-color: #fff; /* Background color */
    padding: 5px 10px; /* Padding inside the dropdown */
    transition: all 0.3s ease; /* Smooth hover effect */
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-dropdown:focus {
    border-color:black !important; /* Border color on focus */
    box-shadow: none; /* Remove default focus outline */
}

.custom-checkbox .form-check-input {
    border: 1px solid black; /* Add black border to the checkbox */
  }

  @media (max-width: 768px) { /* Targets screens 768px wide or smaller */
    .joinsale {
      /* padding-left: 35px; */
      /* text-align: center; */
      font-size: 1.5rem; /* Slightly smaller font for smaller screens */
    }
  }

/* Default styles for all screens */
.submitbtn {
    width: 50%; /* Default width */
    height: 50px; /* Default height */
    background-color: #EF4C0B !important;
    border: none !important;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s ease; /* Smooth transition for responsiveness */
}

/* Medium screens and above (≥768px) */
@media (min-width: 768px) {
    .submitbtn {
        width: 40%; /* Reduce width on larger screens */
        font-size: 18px; /* Slightly larger font size */
    }
}

/* Large screens and above (≥992px) */
/* @media (min-width: 992px) {
    .submitbtn {
        width: 30%; /* Further reduce width */
        /* height: 60px; /* Increase height for better spacing */
        /* font-size: 20px; /* Larger font for better visibility */
    /* }
} */ 

/* Small screens and below (<768px) */
@media (max-width: 767px) {
    .submitbtn {
        width: 100%; /* Increase width for smaller screens */
        height: 45px; /* Slightly smaller height */
        font-size: 14px; /* Smaller font size */
    }
}

@media (max-width: 768px) {
    .shadow-container[style] {
        padding: 10px !important; /* Less padding for mobile view */
        padding-left: 10px !important; /* Adjust padding-left for mobile */
    }
}