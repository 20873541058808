


.coupon-container {
    position: relative;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    left: 90%;
    z-index: 10;
  }
  
  .cross {
    font-size: 20px;
    color: #000;
  }
  
  .coupon-content {
    z-index: 1;
   
  }
  
  .coupon-image {
    max-width: 80%; 
    border-radius: 10px;
  }
  
  .discount-text {
    text-align: left;
    font-weight: bold;
  }
  
  .details-list {
    
    padding: 0;
    margin: 0;
  }
  
  .details-list ul {
   
    padding: 0;
  }
  
  .details-list li {
    padding: 5px 0;
    font-size: 1rem;
  }
  
  .horizontal-line {
    width: 90%;
  }
  
  .qr-image {
    max-width: 100px;
  }
  
  .icon-image {
    max-width: 25px;
  }
  
  .info-text {
    font-size: 0.9rem;
  }
  
 
  .image-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  .background-image {
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
 
  @media (max-width: 1200px) {
    .coupon-container {
      padding: 18px;
    }
    .coupon-image {
      max-width: 75%;
    }
    .qr-image {
      max-width: 95px;
    }
    .discount-text {
      font-size: 1.6rem;
    }
    .details-list li {
      font-size: 1rem;
    }
    .info-text {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 992px) {
    .coupon-container {
      padding: 16px;
    }
  
    .coupon-image {
      max-width: 70%;
    }
  
    .qr-image {
      max-width: 90px;
    }
  
    .discount-text {
      font-size: 1.5rem;
    }
  
    .details-list li {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    .coupon-container {
      padding: 15px;
    }
  
    .coupon-image {
      max-width: 65%;
    }
  
    .qr-image {
      max-width: 80px;
    }
  
    .info-text {
      font-size: 0.8rem;
    }
  
    .discount-text {
      font-size: 1.4rem;
    }
  
    .details-list li {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .coupon-container {
      padding: 12px;
    }
  
    .coupon-image {
      max-width: 60%;
    }
  
    .qr-image {
      max-width: 70px;
    }
  
    .icon-image {
      max-width: 20px;
    }
  
    .discount-text {
      font-size: 1.2rem;
    }
  
    .info-text {
      font-size: 0.7rem;
    }
  
    .details-list li {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 400px) {
    .coupon-container {
      padding: 10px;
    }
  
    .coupon-image {
      max-width: 55%;
    }
  
    .qr-image {
      max-width: 65px;
    }
  
    .icon-image {
      max-width: 18px;
    }
  
    .discount-text {
      font-size: 1rem;
    }
  
    .info-text {
      font-size: 0.65rem;
    }
  
    .details-list li {
      font-size: 0.75rem;
    }
  }
  
